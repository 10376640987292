.booking-summary-bar__pricePopover__body {
  background-color: $secondary;
  border-radius: unset;

  z-index: 1010;

  .popover-body {
    color: $secondaryColorContrast;
  }
}

.booking-summary-bar__container {
  box-shadow: $heavyTopShadow !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  z-index: 1001;

  &__mobile {
    box-shadow: $heavyTopShadow !important;
  }
}

.booking-summary-bar__details-modal {
  &__container {
    margin: 0 !important;
    height: 100% !important;
  }
  &__content {
    .flight-segment-details__icon {
      color: $primary;
    }
    > div > span > span {
      text-wrap: nowrap;
    }
  }
}
.booking-summary-bar__total {
  text-wrap: nowrap;
  padding-right: 5px;
  display: block;
  color: $priceColor !important;
}

.booking-summary-bar__pricePopover {
  .arrow::after {
    border-top-color: $secondary !important;
  }
  .close {
    font-size: small;
    padding: 0;
  }
}
