@import '../utils/mediaQueries';
@import '../variables';

.fixed-scroll__element {
  position: sticky !important;
}

.best-price-package-details {
  .picture-gallery {
    &__large {
      padding-top: 45%;
      flex: 0 1 100%;

      @include medium {
        flex: 0 1 calc(71% - 0.18rem);
      }
    }

    &__small {
      flex: 0 1 calc(29% - 0.18rem);

      &__img {
        border-radius: 15px;
        box-shadow: 0 4px 8px $gray-400;
      }
    }

    &__img {
      border-radius: 15px;
      box-shadow: 0 4px 8px $gray-400;
    }
  }

  .showMoreButton.container,
  .showLessButton.container {
    position: relative;
    margin-bottom: 24px;
  }

  .showMoreButton::before {
    content: '';
    display: block;
    height: 4em;
    width: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 3em;
    left: 0;
    z-index: 2;
  }

  .showMoreButton,
  .showLessButton {
    .col {
      display: flex;
      justify-content: center;
      hr {
        width: 100%;
        border-color: $primary;
      }
      button {
        border: 2px solid $primary;
        color: $primary;
        border-radius: 4px;
        z-index: 3;
      }
    }
  }

  &__table {
    width: 100%;
  }

  &__modal {
    max-width: 1200px;
    padding: 0 15px;

    .modal-body {
      padding-top: 50px;
    }

    &__close {
      position: absolute;
      z-index: 1;
      font-size: 30px;
      top: 8px;
      right: 15px;
      cursor: pointer;
      margin-right: 0 !important;
    }
  }

  &__geo-location {
    font-size: 14px;
    font-weight: 600;
  }

  &__map {
    > div {
      border-radius: 8px;
      overflow: hidden;
    }
  }

  #details-overview {
    .iso__star__rating {
      font-size: 20px;
      position: relative;
      top: -2px;
    }
  }

  .anchorList {
    border-radius: 8px;
    box-shadow: 0 4px 10px #3f4f5a28;
    top: 0;
    z-index: 0 !important;

    .nav__outer {
      width: 100%;
      .nav__inner {
        width: 100% !important;
        .nav {
          display: flex;
          width: 100% !important;
          .nav-item {
            flex: 1 100%;
            text-align: center;
          }
        }
      }
    }

    .nav__button__container {
      width: 14px;
      height: 28px;
      background-color: rgba(213, 1, 1, 0.65);
    }

    .nav-link.anchorList__link.active {
      background-color: white;
      color: initial;
      box-shadow: inset 0px -6px 0px $accentColor;

      &::after {
        content: '';
        height: 70%;
        width: 2px;
        position: absolute;
        right: -2px;
        top: 5px;
        background-color: $gray-200;
        z-index: 1;
      }
    }

    .anchorList__link:not(.active):after {
      z-index: 1;
    }
  }

  &__tags {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    span {
      width: fit-content;
      background: $accentColor;
      color: $accentColorContrast;
      margin: 0 0 5px 5px;
      padding: 0 5px;
      border-radius: 10px;
    }
  }

  &__gallery-container {
    position: relative;
    &__hotel-logo-container {
      height: auto;
      width: 100px;
      bottom: 0;
      position: absolute;
      z-index: 2;
    }

    &__hotel-logo {
      height: auto;
      width: 100%;
      border-bottom-left-radius: 0.9rem;
    }
  }
}

.bestprice-price-details {
  background-color: $white;
  min-height: 100px;
  padding: 12px;

  &__modal__container .modal-content {
    padding: 1rem;
  }

  &__print {
    background-color: $primary;
    color: $white;
    border-color: $primary;

    &:hover {
      border-color: $primary;
    }
  }

  &__flight-separator {
    height: 2px;
    background-color: #e4ecef;
    padding-bottom: 0;
    display: inline-block;
    flex: 1 0 auto;
  }

  &__total {
    margin-top: 40px;
    margin-bottom: 10px;

    .price__without__discount {
      padding-left: 5px;
      white-space: nowrap;
      display: inline-block;
      font-size: 0.875rem;
      & .iso__price__value {
        font-weight: 400;
        text-decoration: line-through;
      }
    }
    .from__text {
      font-size: 0.875rem;
      margin-left: 7px;
    }

    .finale__price {
      font-size: 1.7rem;
      display: inline-block;
      color: $priceColor;
      white-space: nowrap;
    }
    .per__person {
      font-size: 0.75rem;
      text-align: right;
      margin-top: -8px;
    }
    &__price {
      text-align: right;
    }
  }

  &__content {
    flex: 1 0 calc(100% - 28px);

    > span:first-child,
    > div:first-child {
      font-weight: 700;
    }

    &__container {
      display: flex;
      margin-top: 20px;
    }

    &__flight-date {
      width: 100px;
    }

    &__flight-destinations {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 158px;
    }

    .flight-segment-details__icon {
      color: $primary;
    }
  }

  &__icon {
    flex: 1 0 28px;

    svg {
      color: $primary;
    }
  }

  &--mobile {
    position: fixed;
    width: 100%;
    box-shadow: 0 6px 20px #80808030;
    background-color: $white;
    padding: 12px;
    bottom: 0;
    z-index: 4;
    left: 0;
    height: fit-content;
    padding-top: 0;

    @include large {
      display: none;
    }

    .bestprice-price-details {
      padding: 0 0 12px;
      min-height: initial;
      max-height: 50px;

      &__total {
        margin-top: 0;
      }
    }

    &__container {
      margin: 0;
      height: 100%;

      .modal-content {
        height: 100%;
      }

      @include small {
        margin: 1.75rem 8px;
        height: initial;
      }
    }
  }

  &__modal {
    padding-top: 2rem;
  }

  &__divider {
    margin: 10px -12px 0;
    height: 1px;
    background-color: $gray-300;
  }

  &__components {
    margin-top: 10px;
  }
}

.flight-segment__details span {
  color: $gray-600;
}

.fixed-scroll__element {
  padding: 0 0 0 8px;
  display: none;

  @include large {
    display: block;
  }

  .fixed-scroll-element-container {
    box-shadow: $defaultShadow;
    border-radius: 15px;
  }
}

.best-price__list-selections {
  &__rate {
    transition: background-color 0.2s linear;

    &:hover {
      background-color: $lightturquoise;
    }

    &--selected {
      background-color: $lightturquoise;
      border: 1px solid $secondary !important;
    }
  }

  &__duration {
    border: 1px solid $secondary;
    border-radius: 0.25rem;
  }

  &__content-container {
    &__flights {
      @include medium {
        padding-top: 20px;
      }

      @include large {
        padding-top: 0;
      }
    }

    .iso__service-search {
      .select__indicator-separator {
        display: none;
      }
    }

    .geounit-checkbox {
      &.geounit-checkbox--open .geounit-checkbox__angle {
        transform: translateY(0) rotate(0deg);
      }

      .geounit-checkbox {
        &__container {
          min-width: 450px !important;
        }

        &__popover {
          @include small {
            transform: initial;
          }
        }
      }
    }

    .iso__dropdown__toggle > div {
      > div:first-child {
        order: 2;
        right: -3px;
        position: relative;

        svg {
          color: $primary;
        }
      }
    }
  }

  &__selected-item {
    top: 1px;
    right: 1px;
    background-color: darken($lightturquoise, 8%);
    color: $secondary;
    border-left: 1px solid $secondary;
    border-bottom: 1px solid $secondary;
    display: flex;
    align-items: center;
    padding: 0 3px 0 5px;
    border-bottom-left-radius: 12px;
    z-index: 1;
  }

  &__spinner {
    .best-price-custom-spinner {
      &::before {
        border-color: lighten($accentColor, 10%);
      }

      div {
        border-color: $accentColor transparent transparent transparent;
      }

      + div {
        font-size: 24px;
        font-weight: 700;
        margin-top: 10px;
        color: $accentColor;
        opacity: 1;
      }
    }
  }

  &__modal {
    &__close {
      color: black;
    }
  }
  &__direct-flights {
    .form-toggle__input {
      &:checked + .form-toggle__toggler {
        background-color: $secondary;
      }
    }
    .form-toggle__toggler {
      &:after {
        background: $white !important;
      }
    }
  }
}

.best-price__list-selections__flights .scrollable {
  @include medium {
    max-height: 600px;
    overflow-y: scroll;
  }
}

.best-price__list-selections__flights {
  .best-price__list-selections__flight {
    margin-top: 24px;
    box-shadow: $defaultShadow;

    @include medium {
      margin-top: 5px;
    }
  }

  .flight-result-row {
    background-color: $white;
    cursor: pointer;
    margin-left: 0 !important;
    margin-right: 0 !important;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: $lightturquoise;

      .airlines-box {
        background-color: $lightturquoise;
      }
    }

    &--selected {
      background-color: $lightturquoise;
      border: 1px solid $secondary !important;

      .airlines-box {
        background-color: $lightturquoise;
      }

      .flight-search-result-data-right > div > div:last-child::before {
        background-color: $secondary !important;
      }
    }

    .flight-result__single-row {
      margin-top: 0.5rem;
    }

    .airlines-box {
      padding-top: 3px !important;

      img {
        max-width: 100px !important;
        width: auto;
        height: auto;
      }
    }

    .flight-result__single-row:first-child .airlines-box {
      padding-top: 18px !important;
    }

    .flight-search-result-data-right {
      padding-top: 0 !important;
      padding-bottom: 10px;

      &__price-container {
        margin-top: 15px;
        span {
          color: $darkGreen;
        }
      }

      > div > div:first-child {
        display: none;
      }

      > div > div:last-child {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        position: relative;

        &::before {
          content: '';
          display: inline-block;
          height: 1px;
          background-color: $gray-400;
          width: calc(100% - 20px);
          left: 10px;
          top: 6px;
          position: absolute;
        }

        > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row-reverse;

          .luggage-label {
            margin-right: auto;
            display: flex;
            justify-content: end;
            align-items: center;
          }

          @include large {
            flex-direction: column;
            justify-content: flex-end;
          }
        }
      }

      > div > div:last-child > div > div:first-child {
        display: none;
      }

      .iso__price__value {
        color: $primary;
        font-size: 20px;
        white-space: nowrap;
      }

      .total-price-details-label {
        font-weight: 700;
        font-size: 12px;
        position: relative;
        top: 12px;

        @include large {
          top: initial;
        }
      }

      .luggage-label button {
        display: flex !important;
        align-items: center;
      }

      button {
        display: none;
      }

      .iso__price-list-item-layout {
        font-size: 1rem;
        color: $primary;
        margin-left: 10px;
        position: relative;
        top: 8px;

        @include large {
          top: initial;
        }

        > div > div > span {
          font-size: 1.5rem;
          margin-left: -5px;
        }
      }
    }

    .flightLogo {
      border-color: $gray-400;
    }

    .flight-date + div {
      display: none;
    }

    .flightSegmentInfo {
      align-items: center;
      margin-top: auto;

      > div:first-child {
        white-space: nowrap;
        font-weight: 700;
      }

      div.text-right,
      div.text-left {
        margin-top: 0;
        margin-bottom: auto;
      }
    }

    .flight-segment-info-luggage {
      margin-bottom: 0;
      margin-top: auto;

      .luggageIncluded {
        color: $darkGreen !important;
      }
    }

    .airlineLogo {
      max-width: none !important;
      width: 100px;

      img {
        max-width: 100px !important;
        max-height: 35px;
        width: auto;
        height: auto;
      }
    }

    @include large {
      margin-left: 0 !important;
      margin-right: 0 !important;
      display: flex;

      &:not(:last-child) {
        border-bottom: initial;
      }

      &:last-child {
        border-bottom: initial;
      }

      > div:first-child {
        display: flex;
        flex: 1 0 calc(10 / 12 * 100%);
        max-width: calc(10 / 12 * 100%);
        padding-right: 0;
        padding-left: 0;

        > .flight-result__single-row {
          flex: 1 0 50%;
          max-width: 50%;
          margin: 0 !important;

          .airlines-box {
            display: none;
          }

          .flights-box {
            display: flex;
            flex-direction: column;
            &-divider::after {
              display: inline-block;
              content: '';
              position: absolute;
              right: 0;
              top: 20%;
              height: 60%;
              width: 2px;
              background-color: $gray-400;
            }

            img {
              max-height: 35px;
            }
          }
        }
      }

      > div:last-child {
        display: flex;
        justify-content: center;
        flex: 1 0 calc(2 / 12 * 100%);
        max-width: calc(2 / 12 * 100%);
        padding-top: 0 !important;
        padding-bottom: 6px;
      }

      .flights-box {
        margin: 0;
        border: 0;
        padding: 16px 15px 8px;

        .airlineLogo {
          padding-left: 0 !important;
          max-width: none !important;
          width: 100px;

          img {
            max-width: 100px !important;
            width: auto;
            height: auto;
          }
        }
      }

      .package-info__data,
      button {
        display: none;
      }

      .flight-search-result-data-right {
        padding-right: 15px;
        padding-bottom: initial;

        > div > div:first-child {
          display: none;
        }

        > div > div:last-child {
          flex: 0 0 100% !important;
          max-width: 100% !important;

          &::before {
            content: none;
          }

          > div {
            display: block;
          }
        }

        .total-price-details-label {
          margin-top: -6px;
        }

        .iso__price__value {
          white-space: nowrap;
        }
      }
    }
  }
}
.best-price__list-selections__flight {
  background-color: white !important;
  &:not(:last-child) {
    //border-bottom: 5px solid $gray-400;
  }

  &:last-child {
    border-bottom: 1px solid $gray-400;
  }

  @include large {
    &:not(:last-child) {
      border-bottom: 1px solid $gray-400;
    }

    &:last-child {
      border-bottom: initial;
    }
  }
}
.best-price__list-selections__warning {
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 100%;
  padding: 1rem;
  span {
    font-weight: 700;
    font-size: 16px;
  }
}

.pax-room-selection {
  &__button {
    border-radius: 0.25rem;
    border: 1px solid $secondary;
    font-weight: normal;
  }

  &__modal {
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
    }

    &__label {
      font-size: 25px;
    }

    &__close {
      font-size: 25px;
      cursor: pointer;
    }
  }
}
.package-info__price {
  > div > div:first-child {
    color: $priceColor !important;
    white-space: nowrap;
    padding-left: 5px;
  }
}

.list-selections__item--selected {
  .best-price__list-selections--price__tag span {
    color: $black;
  }
}
.best-price__list-selections--discount__tag__wrapper {
  justify-content: center;
}

@media (max-width: 1350px) {
  .best-price__list-selections--discount__tag__wrapper {
    justify-content: start;
  }
}

.best-price__list-selections--departure__price {
  justify-content: flex-end;
}

.best-price__list-selections--price {
  width: 160px;
}

.best-price__list-selections--departure__date {
  width: 140px;
}

.best-price__list-selections--internalFlightLogo {
  width: 24px;
  height: 24px;
}

.booking-summary-bar__container {
  .luggageIncluded {
    color: $darkGreen;
    font-weight: 700;
  }
}

.booking-summary-bar__container .flight-segment-details {
  color: $gray-600;
}

.best-price__list-selections__modal__selections__item {
  .luggageIncluded {
    color: $darkGreen;
    font-weight: 700;
  }
}

.best-price__list-selections__room--separator {
  border-right: 0px solid gray !important;
  border-left: 0px solid gray !important;
}
.best-price__list-selections__content-container {
  padding-bottom: 16px;
}

.best-price__list-selections__content-container--room {
  box-shadow: none !important;
  background-color: $white !important;
  padding-bottom: 0;
  & .best-price__list-selections__warning {
    background-color: $gray-100;
    box-shadow: 0 0 18px -3px rgba(0, 0, 0, 0.3);
    padding-bottom: 50px;
    min-height: 140px;
  }
}
.best-price__list-selections__room {
  border-radius: 16px;
  padding-bottom: 16px;
  margin-bottom: 30px;
  background-color: $gray-100;
  box-shadow: 0 0 18px -3px rgba(0, 0, 0, 0.3);
  min-height: initial;
}
